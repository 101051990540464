import { Address } from './address';

export class User {
  id!: number;
  fullname!: string;
  code!: string;
  type!: string;
  avatar!: string;
  email!: string;
  contact!: number;
  address!: Address;
}
