import { store } from '@/store';
import api from '..';
import { ACCESS_TOKEN, AUTH_TOKEN } from '@/constants';
import { User } from '@/models/user';

const getLoggedUser = async (clientToken: string | null) => {
  try {
    console.log('chek then api');
    const baseUrl = process.env.VUE_APP_API_URL;
    const response = await api.post<User>(`${baseUrl}/me`, {
      clientToken: clientToken,
    });
    // console.log('getter user api', response);

    return response.data;
  } catch (error) {
    // console.log(error);
    store.commit('LOGOUT_USER');
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
  }
};

export default getLoggedUser;
