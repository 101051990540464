<template>
  <v-app-bar
    app
    absolute
    height="70"
    color="#c9d304"
    flat
    class="position-fixed ma-0"
  >
    <template v-slot:prepend>
      <v-img cover style="width: 200px; height: 100%" :src="logo" />
    </template>

    <v-row class="ma-0 pt-4 w-100 align-baseline">
      <v-spacer></v-spacer>
      <v-icon color="#9b267c" size="22">mdi-map-marker-outline</v-icon>
      <small style="color: #9b267c" class="text-h5 font-weight-medium"
        >Mozambique</small
      >
      <v-spacer></v-spacer>

      <v-icon color="#9b267c" size="22">mdi-map-marker-outline</v-icon>
      <small style="color: #9b267c" class="text-h5 font-weight-medium"
        >Johannesburg</small
      >
      <v-spacer></v-spacer>

      <v-icon color="#9b267c" size="22">mdi-map-marker-outline</v-icon>
      <small style="color: #9b267c" class="text-h5 font-weight-medium"
        >Lisboa</small
      >
      <v-spacer></v-spacer>
    </v-row>
  </v-app-bar>

  <v-main class="pt-0" style="height: 100%">
    <v-container fluid class="">
      <h1 class="text-h4 text-center text-primary-lighten-1">
        Simulação de Envio
      </h1>
      <div>
        <p class="text-primary-lighten-2 text-center text-body">
          Assimule um envio e tenha ideia de quanto podera pagar
        </p>
        <v-row class="ma-0 justify-center">
          <div class="text-center w-25">
            <v-row class="ma-0">
              <v-col cols="12" md="6" class="pt-0">
                <div class="text-subtitle-1 text-left text-medium-emphasis">
                  Origem
                </div>
                <v-autocomplete
                  density="compact"
                  hide-details="auto"
                  :items="scales"
                  :disabled="scales.length == 0 ? true : false"
                  :loading="scales.length == 0 ? true : false"
                  return-object
                  item-title="name"
                  item-value="id"
                  v-model="origin"
                  variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="pl-0 pt-0">
                <div class="text-subtitle-1 text-left text-medium-emphasis">
                  Destino
                </div>
                <v-autocomplete
                  density="compact"
                  hide-details="auto"
                  :items="scales"
                  :disabled="scales.length == 0 ? true : false"
                  :loading="scales.length == 0 ? true : false"
                  return-object
                  item-title="name"
                  item-value="id"
                  v-model="destiny"
                  variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="pt-0">
                <div class="text-subtitle-1 text-left text-medium-emphasis">
                  Serviço
                </div>
                <v-autocomplete
                  density="compact"
                  hide-details="auto"
                  :items="services"
                  :disabled="services.length == 0 ? true : false"
                  :loading="services.length == 0 ? true : false"
                  return-object
                  item-title="name"
                  item-value="id"
                  v-model="service"
                  variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="pl-0 pt-0">
                <div class="text-subtitle-1 text-left text-medium-emphasis">
                  Peso
                </div>
                <v-text-field
                  v-model.number="weight"
                  color="primary"
                  base-color="primary"
                  variant="outlined"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <v-row class="ma-0 justify-center">
          <v-btn
            v-if="!hasData"
            prepend-icon="mdi-check"
            color="primary"
            variant="tonal"
            @click.prevent="simulate"
            :loading="btnLoading"
          >
            Simular
          </v-btn>
          <v-btn
            v-else
            prepend-icon="mdi-close"
            color="primary"
            variant="tonal"
            @click.prevent="clearData"
          >
            Limpar
          </v-btn>
        </v-row>
      </div>
      <v-row v-show="hasData" class="ma-0">
        <v-col cols="12" md="6" class="mx-auto">
          <v-card class="pa-4" color="muted">
            <h4>Resultado</h4>

            <div class="">
              <h5>
                O valor de envio de
                <strong>{{ cotResult.weight }}KG </strong> na rota
                <b>{{ cotResult.route }}</b> é de
                <b>{{ cotResult.price }}mts</b>.
              </h5>
              <!-- <v-row class="ma-0 justify-center">
                <v-col class="pa-0">
                  <small
                    class="font-weight-bold pl-1 text-caption text-primary-lighten-2"
                  >
                    Rota
                  </small>
                  <br />
                  <span
                    class="pl-1 text-body-2 font-weight-black ma-0 text-primary-lighten"
                    style="line-height: 0.7"
                  >
                    {{ cotResult.route }}
                  </span>
                </v-col>
              </v-row>

              <v-row class="ma-0 justify-center">
                <v-col class="pa-0">
                  <small
                    class="font-weight-bold pl-1 text-caption text-primary-lighten-2"
                  >
                    Peso
                  </small>
                  <br />
                  <span
                    class="pl-1 text-body-2 font-weight-black ma-0 text-primary-lighten"
                    style="line-height: 0.7"
                  >
                    {{ cotResult.weight }} KG
                  </span>
                </v-col>
              </v-row>

              <v-row class="ma-0 justify-center">
                <v-col class="pa-0">
                  <small
                    class="font-weight-bold pl-1 text-caption text-primary-lighten-2"
                  >
                    Preço
                  </small>
                  <br />
                  <span
                    class="pl-1 text-body-2 font-weight-black ma-0 text-primary-lighten"
                    style="line-height: 0.7"
                  >
                    {{ cotResult.price }}
                  </span>
                </v-col>
              </v-row> -->
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" md="10" class="mx-auto">
          <v-card
            color="primary-lighten-2"
            prepend-icon="mdi-information-outline"
            text="O vaor da simulação poderá sofrer alterações devido a outras taxas adicionais, este é epenas valor aproximado sem taxas aplicadas."
            title="Valor Sujeito a Alterações"
          ></v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer app height="80" class="bg-pink-darken-4 px-0 d-flex flex-column">
      <v-row class="ma-0 w-100">
        <v-spacer />
        <v-col cols="10">
          <v-row>
            <span style="font-size: 16px" class="text-tertiary">
              Contactos - Maputo - Sede : Av Acordos de Lusaka, nº 3237 | Tell:
              +258 82 120 8151 / +258 84 120 8151;
            </span>
          </v-row>
          <v-row>
            <span style="font-size: 16px" class="text-tertiary">
              Email:
              <a
                class="text-decoration-none text-tertiary"
                href="mailto:info@portadordiario.co.mz"
              >
                info@portadordiario.co.mz </a
              >, Web:
              <a
                class="text-decoration-none text-tertiary"
                href="https://www.portadordiario.co.mz"
                >www.portadordiario.co.mz</a
              >
            </span>
          </v-row>
        </v-col>
        <v-col cols="1">
          <v-row class="">
            <span style="font-size: 16px" class="mx-auto text-tertiary">
              Siga-nos
            </span>
          </v-row>
          <v-row>
            <div class="mx-auto">
              <a
                v-for="(icon, i) in icons"
                :key="i"
                :href="icon.link"
                target="_blank"
                class="text-decoration-none text-white"
              >
                <v-icon color="White" size="26">
                  {{ icon.name }}
                </v-icon>
              </a>
            </div>
          </v-row>
        </v-col>
        <v-spacer />
      </v-row>

      <div
        class="bg-tertiary"
        style="height: 6px; width: 100%; margin-bottom: 4px; margin-top: 4px"
      ></div>

      <v-divider></v-divider>

      <!-- <div>
        {{ new Date().getFullYear() }} — <strong>Portador Diário</strong>
      </div> -->
    </v-footer>

    <error-component
      :dialog="errorDialog"
      :message="errorMsg"
      @close="closeErrorDialog"
    />
  </v-main>
</template>

<script lang="ts">
import { trackingLogo } from '@/assets/images';
import { Track } from '@/models/track';
import { onMounted, ref } from 'vue';
import { defineComponent } from 'vue';
import ErrorComponent from '@/components/feedbacks/ErrorComponent.vue';
import getScales from '@/api/home/getScales';
import getServices from '@/api/home/getServices';
import getCotation from '@/api/home/getCotation';
import { watch } from 'vue';

interface Head {
  id: number;
  name: string;
}

export default defineComponent({
  components: { ErrorComponent },
  setup() {
    const logo = trackingLogo;
    const icons = [
      { name: 'mdi-facebook', link: 'https://facebook.com' },
      { name: 'mdi-instagram', link: 'https://instagram.com' },
      { name: 'mdi-linkedin', link: 'https://linkedin.com' },
    ];

    onMounted(() => {
      getScale();
      getService();
    });

    // error dialog section
    const errorDialog = ref(false);
    const errorMsg = ref<string>('');
    const closeErrorDialog = () => {
      errorDialog.value = false;
      errorMsg.value = '';
    };

    const weight = ref<number>(0);

    const services = ref<Head[]>([]);
    const service = ref<Head>({ id: 0, name: '' });

    const scales = ref<Head[]>([]);
    const origin = ref<Head>({ id: 0, name: '' });
    const destiny = ref<Head>({ id: 0, name: '' });

    const getScale = async () => {
      try {
        const res = await getScales();
        scales.value = res;
      } catch (error: any) {
        // console.error('Error fetching data:', error);
      } finally {
        loading.value = false;
      }
    };

    const getService = async () => {
      try {
        const res = await getServices();
        services.value = res;
      } catch (error: any) {
        // console.error('Error fetching data:', error);
      } finally {
        loading.value = false;
      }
    };

    const code = ref();
    const loading = ref(false);
    const btnLoading = ref(false);
    const hasData = ref(false);

    const cotResult = ref({
      price: '',
      route: '',
      weight: 0,
    });
    const myPack = ref<Track>();

    watch(destiny, (val) => {
      if (val == origin.value) {
        errorMsg.value =
          'Não pode enviar para mesma escala, tente outro destino.';
        errorDialog.value = true;
        destiny.value = { id: 0, name: '' };
      }
    });

    const simulate = async () => {
      btnLoading.value = true;

      const data = {
        service: service.value.id,
        origin: origin.value.id,
        destiny: destiny.value.id,
        weight: weight.value,
      };

      try {
        const res = await getCotation(data);
        setData(res);

        // console.log(res);
      } catch (error: any) {
        // console.error('Error fetching data:', error);
      } finally {
        btnLoading.value = false;
      }
    };

    const setData = (data: any) => {
      if (data.price) {
        cotResult.value = data.price;
        hasData.value = true;
        btnLoading.value = false;
      } else {
        errorMsg.value = data.error;
        errorDialog.value = true;
        btnLoading.value = false;
      }
    };

    const clearData = () => {
      weight.value = 0;
      service.value = { id: 0, name: '' };
      origin.value = { id: 0, name: '' };
      destiny.value = { id: 0, name: '' };

      hasData.value = false;
      cotResult.value = {
        price: '',
        route: '',
        weight: 0,
      };
    };

    return {
      errorDialog,
      errorMsg,
      closeErrorDialog,
      setData,
      cotResult,
      clearData,
      logo,
      icons,
      code,
      myPack,
      simulate,
      getCotation,
      hasData,
      weight,
      services,
      service,
      scales,
      origin,
      destiny,
      getScale,
      getService,
      btnLoading,
    };
  },
});
</script>
