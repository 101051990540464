import api from '..';
import { Track } from '@/models/track';

const trackPack = async (code: string) => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Track>(`${baseUrl}/packTrack/${code}`);

  return response.data;
};

export default trackPack;
