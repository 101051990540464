import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-right" }
const _hoisted_2 = { class: "text-center pb-4" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.errorDialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.errorDialog) = $event)),
    "max-width": "310px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", {
              icon: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
              class: "ml-auto float-right mt-1 mr-1",
              right: "",
              style: {"cursor":"pointer"}
            }, [
              _createVNode(_component_v_icon, {
                color: "primary-lighten-1",
                size: "x-small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-close")
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_v_icon, {
              size: "32",
              color: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-close-circle")
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_v_card_text, { class: "text-center py-0 px-2" }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.errorProp,
                class: "text-body-2 text-primary-lighten-1 font-weight-light"
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "primary",
                size: "x-small",
                variant: "flat",
                height: "22px",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
                rounded: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" OK ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}