import { Timeline } from './timeline';

export class Track {
  id!: string;
  origin!: string;
  ori_branch!: string;
  destination!: string;
  des_branch!: string;
  client!: string;
  service!: string;
  track_no!: string;
  pickup_code!: string;
  waybill!: string;
  pack_status!: string;
  ref!: string;
  content!: string;
  description!: string;
  weight!: string;
  price!: string;
  pickup!: string;
  date!: string;
  status!: string;
  barval!: number;
  counter!: number;
  tracking!: Timeline[];
}
