const routes = [
  {
    path: '',
    name: 'admin',
    meta: {
      hide: true,
      requiresAuth: true,
    },

    component: () => import('@/modules/admin/layout/MainLayout.vue'),

    children: [
      // {
      //   path: '',
      //   name: 'layout',
      //   // component: () =>
      //   //   import('@/modules/admin/modules/home/views/HomeView.vue'),
      //   children: [
      {
        path: 'packs',
        name: 'home.layout',
        component: () =>
          import('@/modules/admin/modules/home/views/HomeView.vue'),
        children: [
          {
            path: '',
            name: 'packs',
            component: () =>
              import(
                '@/modules/admin/modules/home/components/MailComponent.vue'
              ),
          },
          {
            path: 'pickups',
            name: 'pickups',
            component: () =>
              import(
                '@/modules/admin/modules/home/components/PickupComponent.vue'
              ),
          },
          {
            path: 'processes',
            name: 'processes',
            component: () =>
              import(
                '@/modules/admin/modules/home/components/ProcessComponent.vue'
              ),
          },

          {
            path: 'pre-process',
            name: 'create.pack',
            component: () =>
              import('@/modules/admin/modules/home/views/CreatePack.vue'),
          },
          {
            path: 'pickup-request',
            name: 'create.pickup',
            component: () =>
              import('@/modules/admin/modules/home/views/CreatePickup.vue'),
          },
          {
            path: 'finance',
            name: 'finance.details',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/finance/views/FinanceDetails.vue'
              ),
          },
        ],
      },
      {
        path: 'cotation',
        name: 'cotation',
        component: () =>
          import('@/modules/admin/modules/process/views/ProcessView.vue'),
      },
      {
        path: 'trackings',
        name: 'trackings',
        component: () =>
          import('@/modules/admin/modules/process/views/ProcessView.vue'),
      },
      {
        path: 'settings',
        name: 'settings',
        component: () =>
          import('@/modules/admin/modules/reports/views/ReportView.vue'),
      },
      {
        path: 'profile',
        name: 'profile',
        component: () =>
          import('@/modules/admin/modules/reports/views/ReportView.vue'),
      },
      //   ],
      // },
    ],
  },
  // {
  //   path: 'verify-email',
  //   name: 'verify.email',
  //   component: () => import('@/modules/admin/views/verify/VerifyEmail.vue'),
  //   meta: {
  //     hide: true,
  //     requiresAuth: true,
  //   },
  // },
];

export default routes;
