import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store, key } from './store/index';

// vuetify
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

// ant design
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

//Global stylesheet
import './assets/styles/custom.css'; // Import your custom CSS file here;
require('@/api/subscriber');

//internationalization
import i18n from './i18n'; // Import the i18n configuration

loadFonts();
// Wait for the `FETCH_LOGGED_USER` action to complete before mounting the app
store.dispatch('FETCH_LOGGED_USER').then(() => {
  createApp(App)
    .use(i18n)
    .use(store, key)
    .use(router)
    .use(vuetify)
    .use(Antd)
    .mount('#app');
});
