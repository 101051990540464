import { store } from '@/store';
import api from '.';
import { AUTH_TOKEN } from '@/constants';

store.subscribe((mutation) => {
  switch (mutation.type) {
    case 'LOGIN_USER':
      if (mutation.payload) {
        api.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${mutation.payload}`;
        localStorage.setItem(AUTH_TOKEN, mutation.payload);
      } else {
        api.defaults.headers.common['Authorization'] = null;
        localStorage.removeItem(AUTH_TOKEN);
      }
      break;
  }
});
