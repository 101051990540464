import { Service } from '@/models/service';
import api from '..';

const getServices = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Service[]>(`${baseUrl}/services`);
  // console.log(response.data);

  return response.data;
};

export default getServices;
