import api from '..';
import { Scale } from '@/models/scale';

const getScales = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Scale[]>(`${baseUrl}/scales`);
  // console.log(response.data);

  return response.data;
};

export default getScales;
