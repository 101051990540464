<template>
  <div class="bg-surface" id="global-container">
    <v-container>
      <h1 class="text-h4 text-primary-lighten-1">Not Authorized</h1>
      <p class="text-primary-lighten-2 text-body">
        You are not authorized to access this app.
      </p>
      <v-btn
        prepend-icon="mdi-login"
        color="primary"
        variant="tonal"
        :to="{ name: 'login' }"
      >
        go to login
      </v-btn>
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {};
  },
});
</script>
