export default {
  login: {
    slogan: 'Health for everyone',
  },
  admin: {
    components: {
      error: {
        title: 'error message',
      },
      confirm: {
        confirmTitle: 'Do you want to confirm the sale?',
        cancelTitle: 'Do you want to cancel the sale?',
        confirmBtn: 'Yes',
        cancelBtn: 'No',
      },
      success: {
        title: 'Success message',
      },
    },
    layout: {
      nav: {
        enBtn: 'English',
        ptBtn: 'Portuguese',
      },
    },
    home: {
      delivery: {
        title1: 'Purchases with delivery',
        title2: 'Reservations with delivery',
        options: {
          payment: 'Purchases',
          booking: 'Reservations',
        },
        table: {
          client: 'Client',
          quantity: 'Quantity',
          paidPrice: 'T. A paid',
          date: 'Purchase date',
          contact: 'Contact',
        },
        actions: {
          startChat: 'Start conversation',
          requestDelivery: 'Request delivery',
          confirmDeliery: 'Confirm delivery',
          cancelSale: 'Cancel sale',
          confimReturn: 'Confirm return',
          moreDetails: 'More details',
        },
        success: {
          confirm: 'Sale successfully completed!',
          cancel: 'Sale successfully cancelled!',
        },
      },
      payment: {
        title: 'Purchases',
        status: {
          normal: 'Normal',
          medium: 'Medium',
          low: 'Low',
        },
      },
      booking: {
        title: 'Reservations',
        status: {
          normal: 'Normal',
          medium: 'Medium',
          low: 'Low',
        },
      },
      stock: {
        title: 'Stock status',
        high: 'High stock',
        medium: 'Medium stock',
        low: 'Low stock',
      },
      chat: {
        title: 'Messages',
        table: {
          name: 'Name',
          message: 'Message',
          time: 'Time',
        },
      },
      summary: {
        title: 'Summary of sales made',
        table: {
          image: '',
          product: 'Product',
          soldQtn: 'Q. sold',
          payment: 'Total paid (100%)',
          booking: 'Total paid (50%)',
          available: 'Amount available',
          stock: 'Available stock',
          actions: 'Actions',
          button: 'Update stock',
        },
        labels: {
          excluded: 'Excluded',
        },
      },
    },
    delivery: {
      title1: 'Pending purchases/deliveries',
      title2: 'Pending reservations/deliveries',
      filter: {
        all: 'All sales',
        completed: 'Completed sales',
        rejected: 'Rejected sales',
        purchaseDate: 'Purchase date',
        product: 'Product',
        from: 'from',
        to: 'to',
      },
      options: {
        payment: 'Purchases',
        booking: 'Reservations',
      },
      table: {
        image: '',
        client: 'client',
        products: 'N. of products',
        quantity: 'Quantity',
        PaidPrice: 'T. A paid',
        date: 'Purchase date',
        contact: 'Contact',
        actions: 'Actions',
      },
      innerTable: {
        image: '',
        product: 'Product',
        quantity: 'Quantity',
        price: 'Price',
        paidPrice: 'A. Paid',
      },
      actions: {
        StartChat: 'Start conversation',
        requestDelivery: 'Request delivery',
        confirmDeliery: 'Confirm delivery',
        cancelSale: 'Cancel sale',
        confimReturn: 'Confirm return',
        moreDetails: 'More details',
      },
      deliveryDetails: {
        title: 'More Details',
        table: {
          client: 'Client Name',
          product: 'Produt',
          paid_date: 'Sale Date',
          province: 'Delivery Province',
          address: 'Delivery Address',
          receptorContact: 'Receptor Contact',
          agent: 'Delivery Agent',
          agentContact: 'Delivery Agent Cont.',
          deliveryDate: 'Delivery Date',
        },
        actions: {
          startChat: 'Start conversation',
          requestDelivery: 'Request delivery',
          confirmDeliery: 'Confirm delivery',
          cancelSale: 'Cancel sale',
          confimReturn: 'Confirm return',
          moreDetails: 'More details',
        },
        statuses: {
          canceled: 'Cancelled',
          completed: 'Completed',
        },
      },
      status: {
        normal: 'Normal',
        medium: 'Medium',
        low: 'Low',
      },
      reason: {
        title: 'Reason for cancellation.',
      },
      success: {
        confirm: 'Sale successfully completed!',
        cancel: 'Sale successfully cancelled!',
      },
    },
    payments: {
      title: 'Purchases',
      filter: {
        all: 'All sales',
        completed: 'Completed sales',
        rejected: 'Rejected sales',
        purchaseDate: 'Purchase date',
        product: 'Product',
        from: 'from',
        to: 'to',
      },
      table: {
        image: '',
        client: 'client',
        products: 'N. of products',
        quantity: 'Quantity',
        PaidPrice: 'T. A paid',
        date: 'Purchase date',
        contact: 'Contact',
        actions: 'Actions',
        chat: 'Start Chat',
      },
      innerTable: {
        image: '',
        product: 'Product',
        quantity: 'Quantity',
        price: 'Price',
        paidPrice: 'A. Paid',
      },
      status: {
        normal: 'Normal',
        medium: 'Medium',
        low: 'Low',
      },
      confirm: {
        title: 'Do you want to confirm the sale?',
      },
      cancel: {
        title: 'Do you want to cancel the sale?',
        reasonTitle: 'Indicate the reason in the field below.',
        reasonTitle2:
          'The reason for cancellation will be sent by email to the customer and the amount paid for the product will be refunded',
        placeholder: 'Specify the reason in this field.',
        confirmBtn: 'Confirm',
        cancelBtn: 'Cancel',
      },
      reason: {
        title: 'Reason for cancellation.',
      },
      success: {
        confirm: 'Sale successfully completed!',
        cancel: 'Sale successfully cancelled!',
      },
      labels: {
        canceled: 'Cancelled',
        complete: 'Completed',
        removed: 'Excluded',
      },
    },
    bookings: {
      title: 'Reservations',
      filter: {
        all: 'All sales',
        completed: 'Completed sales',
        rejected: 'Rejected sales',
        purchaseDate: 'Purchase date',
        product: 'Product',
        from: 'from',
        to: 'to',
      },
      table: {
        image: '',
        client: 'client',
        products: 'N. of products',
        quantity: 'Quantity',
        PaidPrice: 'T. A paid',
        date: 'Purchase date',
        contact: 'Contact',
        actions: 'Actions',
        chat: 'Start Chat',
      },
      innerTable: {
        image: '',
        product: 'Product',
        quantity: 'Quantity',
        price: 'Price',
        paidPrice: 'A. Paid',
        actions: '',
      },
      status: {
        normal: 'Normal',
        medium: 'Medium',
        low: 'Low',
      },
      confirm: {
        title: 'Do you want to confirm the sale?',
      },
      cancel: {
        title: 'Do you want to cancel the sale?',
        reasonTitle: 'Indicate the reason in the field below.',
        reasonTitle2:
          'The reason for cancellation will be sent by email to the customer and the amount paid for the product will be refunded',
        placeholder: 'Specify the reason in this field.',
        confirmBtn: 'Confirm',
        cancelBtn: 'Cancel',
      },
      reason: {
        title: 'Reason for cancellation.',
      },
      success: {
        confirm: 'Sale successfully completed!',
        cancel: 'Sale successfully cancelled!',
      },
      labels: {
        canceled: 'Cancelled',
        complete: 'Completed',
        removed: 'Excluded',
      },
    },
    statistics: {
      stock: {
        title: 'Stock status',
        high: 'High stock',
        medium: 'Medium stock',
        low: 'Low stock',
      },
      products: {
        title: 'Detailed stock',
        filter: {
          all: 'all',
          unit: 'unit',
          pack: 'package',
          wallet: 'wallet',
        },
        yAxis: 'Available quantity',
      },
      transactions: {
        title: 'Transactions',
        title2: 'Detailed transactions',
        filter: {
          day: 'Day',
          week: 'Week',
          month: 'Month',
          year: 'Year',
        },
        yAxis: 'Available ammount (MZN)',
      },
      clients: {
        title: 'Clients & Visitors',
        active: 'Active clients',
        visitors: 'Visitors',
        yAxis: 'Total',

        filter: {
          day: 'Day',
          week: 'Week',
          month: 'Month',
          year: 'Year',
        },
      },
    },
    chat: {
      tabs: {
        patient: 'Patient',
        support: 'Support',
      },
      title: 'Messages',
      messagePlaceholder: 'Write your message here',
      menu: {
        photo: 'Attach photo',
        product: 'Add product',
      },
      productSelect: 'Select Product',
    },
    summary: {
      title: 'Summary of sales made',
      table: {
        image: '',
        product: 'Product',
        soldQtn: 'Q. sold',
        payment: 'Total paid (100%)',
        booking: 'Total paid (50%)',
        available: 'Amount available',
        stock: 'Available stock',
        actions: 'Actions',
        button: 'Update stock',
      },
      filter: {
        search: 'Product',
        types: {
          all: 'all',
          unit: 'unit',
          pack: 'package',
          wallet: 'wallet',
          purchaseDate: 'Purchase date',
          from: 'from',
          to: 'to',
        },
      },
      labels: {
        excluded: 'Excluded',
      },
    },
    products: {
      labels: {
        stock: {
          medium: 'Medium stock',
          low: 'Low stock',
        },
        status: {
          pending: 'Pending',
          expired: 'Expired',
        },
      },
      messages: {
        sell: 'Do you intend to make the product available for sale?',
        unsell: 'Do you intend to stop selling the product?',
        delete: 'Do you intend to remove the product?',
        deleteAll: 'Do you intend to remove all the products?',
      },
      errors: {
        expired: 'The product has expired, please replace it.',
        SELLERROR: 'Complete the pending sales process.',
        SELLDELETEERROR:
          'Não foi possivel remover o produto.\nQueira por favor terminar o processo de venda pendente.',
        STOPSELLERROR:
          'Não foi possivel remover o produto.\nQueira porfavor desabilitar a venda.',
      },

      success: {
        saved: 'Product added successfully',
        updated: 'Product updated successfully',
      },

      deleteBtn: 'ELIMINATE ALL',

      filter: {
        search: 'Product, brand',
        label: 'Filter by category',
        stock: 'Stock type',
        cat: 'Category',
      },
      table: {
        image: '',
        product: 'Product',
        brand: 'Brand',
        quantity: 'Quantity',
        price: 'Price',
        category: 'Category',
        expiry: 'Expiry date',
        actions: 'Actions',
      },
      modal: {
        label: 'Add product',
        label2: 'Edit product',
        imgTitle: 'Click here',
        imgSubtitle: 'to add image',
        name: 'Product name',
        brand: 'Brand',
        category: {
          input: 'Category',
          title: 'Add new category',
          label: 'Category',
          cancelBtn: 'Cancel',
          saveBtn: 'Save',
        },
        other: 'Add another one',
        type: 'Stock type',
        price: {
          default: 'Price',
          unit: 'P/unit',
          wallet: 'P/wall',
          pack: 'P/pack',
        },
        priceInfo: '8% of the total product sales value will be deducted.',
        quantity: 'Quantity',
        alert: 'When to alert?',
        expiry: 'Expiry date',
        expiryInfo:
          'Products with expired validity dates are not accepted and will be rejected.',
        descEng: 'Portuguese',
        descPt: 'English',
        cancelBtn: 'CANCEL',
        saveBtn: 'SAVE',
        updateBtn: 'UPDATE',
      },
    },
  },
};
