<template>
  <v-dialog v-model="errorDialog" max-width="310px">
    <v-card>
      <div class="text-right">
        <span
          icon
          @click="$emit('close')"
          class="ml-auto float-right mt-1 mr-1"
          right
          style="cursor: pointer"
        >
          <v-icon color="primary-lighten-1" size="x-small">mdi-close</v-icon>
        </span>
      </div>
      <div class="text-center pb-4">
        <v-icon size="32" color="danger">mdi-close-circle</v-icon>
      </div>
      <v-card-text class="text-center py-0 px-2">
        <span
          v-html="errorProp"
          class="text-body-2 text-primary-lighten-1 font-weight-light"
        >
        </span>
        <!-- <span
          v-html="errorProp"
          class="text-body-2 text-primary font-weight-light"
        >
         {{  errorProp }}
        </span> -->
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          size="x-small"
          variant="flat"
          height="22px"
          @click="$emit('close')"
          rounded
        >
          OK
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const errorDialog = ref(false);
    const errorProp = computed(() => props.message);
    const propDialog = computed(() => props.dialog);

    watch(propDialog, () => {
      errorDialog.value = propDialog.value;
    });

    return {
      errorDialog,
      errorProp,
      propDialog,
    };
  },
});
</script>
