import { createI18n } from 'vue-i18n';

// Import the language files directly (no need for the import() function)
import enMessages from './locales/en';
import ptMessages from './locales/pt';

const messages = {
  en: enMessages,
  pt: ptMessages,
};

const i18n = createI18n({
  legacy: false, // Use the Composition API
  locale: process.env.VUE_APP_DEFAULT_LOCALE || 'pt',
  fallbackLocale: 'pt',
  messages: messages,
});

// Set the language based on the route parameter
const routeLang = window.location.pathname.split('/')[1];
if (process.env.VUE_APP_LANGUAGES?.split(',').includes(routeLang)) {
  i18n.global.locale.value = routeLang;
}
export default i18n;
