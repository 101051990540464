import api from '..';
import { Price } from '@/models/price';
import { getCotationDTO } from './types';

const getCotation = async (data: getCotationDTO) => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.post<Price>(`${baseUrl}/quick_cotation`, data);

  return response.data;
};

export default getCotation;
