<template>
  <v-app-bar
    app
    absolute
    height="70"
    color="#c9d304"
    flat
    class="position-fixed ma-0"
  >
    <template v-slot:prepend>
      <v-img cover style="width: 200px; height: 100%" :src="logo" />
    </template>

    <v-row class="ma-0 pt-4 w-100 align-baseline">
      <v-spacer></v-spacer>
      <v-icon color="#9b267c" size="22">mdi-map-marker-outline</v-icon>
      <small style="color: #9b267c" class="text-h5 font-weight-medium"
        >Mozambique</small
      >
      <v-spacer></v-spacer>

      <v-icon color="#9b267c" size="22">mdi-map-marker-outline</v-icon>
      <small style="color: #9b267c" class="text-h5 font-weight-medium"
        >Johannesburg</small
      >
      <v-spacer></v-spacer>

      <v-icon color="#9b267c" size="22">mdi-map-marker-outline</v-icon>
      <small style="color: #9b267c" class="text-h5 font-weight-medium"
        >Lisboa</small
      >
      <v-spacer></v-spacer>
    </v-row>
  </v-app-bar>

  <v-main class="pt-0" style="height: 100%">
    <v-container fluid class="">
      <h1 class="text-h4 text-center text-primary-lighten-1">Track & Trace</h1>
      <div v-if="!hasData">
        <p class="text-primary-lighten-2 text-center text-body">
          Use o seu código de tracking para rastrear o seu correio ou carga.
        </p>
        <v-row class="ma-0 justify-center">
          <v-col cols="4" class="py-1 px-0">
            <v-text-field
              placeholder="Código de tracking"
              prepend-inner-icon="mdi-marker"
              variant="outlined"
              color="primary"
              base-color="primary-dark"
              rounded="md"
              hide-details="auto"
              v-model="code"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 justify-center">
          <v-btn
            prepend-icon="mdi-map-marker-path"
            color="primary"
            variant="tonal"
            @click.prevent="trackPackage"
          >
            Track
          </v-btn>
        </v-row>
      </div>
      <v-row v-else class="justify-center ma-0">
        <v-row class="justify-center">
          <v-col cols="12">
            <v-row class="justify-center">
              <v-btn
                class="py-1"
                prepend-icon="mdi-login"
                color="primary"
                variant="tonal"
                @click.prevent="newTracking"
              >
                Novo Tracking
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="10" class="pa-4">
            <v-card class="pa-4">
              <v-row>
                <v-col cols="6">
                  <v-col>
                    <v-row class="text-h5"> Ref: {{ myPack?.ref }} </v-row>
                    <v-row class="text-body-1">
                      Destinatário: {{ myPack?.client }}</v-row
                    >
                    <v-row class="text-body-1">
                      Peso: {{ myPack?.weight }}Kg
                    </v-row>
                    <v-row class="text-body-1">
                      Data: {{ myPack?.date }}
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="5" class="">
                    <v-row class="text-caption mb-4 text-primary-lighten-2">
                      More Details
                    </v-row>
                    <v-row class="text-body-1">
                      <label class="text-primary-lighten-2">Rota:</label>
                      {{ myPack?.origin }} - {{ myPack?.destination }}
                    </v-row>
                    <v-row class="text-body-1">
                      <label class="text-primary-lighten-2"
                        >Levantamento:</label
                      >
                      {{ myPack?.pickup }}
                    </v-row>
                    <v-row class="text-body-1">
                      <label class="text-primary-lighten-2"
                        >Codigo de Levantamento:</label
                      >

                      {{ myPack?.pickup_code }}
                    </v-row>
                    <v-row class="text-body-1">
                      <label class="text-primary-lighten-2"
                        >Destinatário:</label
                      >

                      {{ myPack?.client }}
                    </v-row>
                    <v-row class="text-body-1">
                      <v-col class="px-0">
                        <label class="text-primary-lighten-2">Conteudo</label>
                        <div>{{ myPack?.content }}.</div>
                      </v-col>
                    </v-row>

                    <v-row class="text-body-1">
                      <v-col class="px-0">
                        <label class="text-primary-lighten-2">Descrição</label>
                        <div>{{ myPack?.description }}.</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-timeline
                    line-color="primary"
                    line-thickness="2"
                    align="start"
                    density="compact"
                  >
                    <v-timeline-item
                      v-for="(tracking, i) in myPack?.tracking"
                      :key="i"
                      :dot-color="tracking.status == true ? 'primary' : 'muted'"
                      fill-dot
                      size="x-small"
                    >
                      <div class="mb-4">
                        <div>
                          <span
                            :class="
                              tracking.status == true
                                ? 'text-primary-dark'
                                : 'text-muted'
                            "
                            class="font-weight-black"
                          >
                            {{ tracking.date }}
                          </span>
                        </div>

                        <div>
                          <span
                            :class="
                              tracking.status == true
                                ? 'text-primary-lighten-1'
                                : 'text-muted'
                            "
                            class="text-body-2 font-weigh-light"
                          >
                            {{ tracking.action }}
                          </span>
                          <br />
                          <span
                            :class="
                              tracking.status == true
                                ? 'text-primary-lighten-1'
                                : 'text-muted'
                            "
                            class="text-caption font-weigh-light"
                          >
                            {{ tracking.point }}
                          </span>
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-row>

      <v-row>
        <v-col cols="10" class="mx-auto">
          <div class="text-h5 mt-4 mb-2">Perguntas Frequentes</div>

          <v-expansion-panels variant="accordion">
            <v-expansion-panel v-for="(faq, i) in faqs" :key="i">
              <v-expansion-panel-title
                class="font-weight-strong"
                expand-icon="mdi-menu-down"
              >
                {{ faq.quiz }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ faq.text }}
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <v-footer app height="80" class="bg-pink-darken-4 px-0 d-flex flex-column">
      <v-row class="ma-0 w-100">
        <v-spacer />
        <v-col cols="10">
          <v-row>
            <span style="font-size: 16px" class="text-tertiary">
              Contactos - Maputo - Sede : Av Acordos de Lusaka, nº 3237 | Tell:
              +258 82 120 8151 / +258 84 120 8151;
            </span>
          </v-row>
          <v-row>
            <span style="font-size: 16px" class="text-tertiary">
              Email:
              <a
                class="text-decoration-none text-tertiary"
                href="mailto:info@portadordiario.co.mz"
              >
                info@portadordiario.co.mz </a
              >, Web:
              <a
                class="text-decoration-none text-tertiary"
                href="https://www.portadordiario.co.mz"
                >www.portadordiario.co.mz</a
              >
            </span>
          </v-row>
        </v-col>
        <v-col cols="1">
          <v-row class="">
            <span style="font-size: 16px" class="mx-auto text-tertiary">
              Siga-nos
            </span>
          </v-row>
          <v-row>
            <div class="mx-auto">
              <a
                v-for="(icon, i) in icons"
                :key="i"
                :href="icon.link"
                target="_blank"
                class="text-decoration-none text-white"
              >
                <v-icon color="White" size="26">
                  {{ icon.name }}
                </v-icon>
              </a>
            </div>
          </v-row>
        </v-col>
        <v-spacer />
      </v-row>

      <div
        class="bg-tertiary"
        style="height: 6px; width: 100%; margin-bottom: 4px; margin-top: 4px"
      ></div>

      <v-divider></v-divider>

      <!-- <div>
        {{ new Date().getFullYear() }} — <strong>Portador Diário</strong>
      </div> -->
    </v-footer>

    <error-component
      :dialog="errorDialog"
      :message="errorMsg"
      @close="closeErrorDialog"
    />
  </v-main>
</template>

<script lang="ts">
import { trackingLogo } from '@/assets/images';
import trackPack from '@/api/site/trackPack';
import { Track } from '@/models/track';
import { ref } from 'vue';
import { defineComponent } from 'vue';
import ErrorComponent from '@/components/feedbacks/ErrorComponent.vue';

export default defineComponent({
  components: { ErrorComponent },
  setup() {
    const logo = trackingLogo;
    const icons = [
      { name: 'mdi-facebook', link: 'https://facebook.com' },
      { name: 'mdi-instagram', link: 'https://instagram.com' },
      { name: 'mdi-linkedin', link: 'https://linkedin.com' },
    ];

    const faqs = [
      {
        quiz: 'O que é um número de rastreamento e onde posso encontrá-lo?',
        text: 'Um número de rastreamento ou ID é uma combinação de números e, possivelmente, letras que identifica de forma exclusiva seu Correio/Carga para rastreamento nacional ou internacional.',
      },
      {
        quiz: 'Quando as informações de rastreamento aparecerão?',
        text: 'Os eventos de rastreamento normalmente aparecem 24 a 48 horas após o recebimento do ID de rastreamento. Em geral, uma vez que o Correio/Carga tenha chegado à nossa instalação, um evento de rastreamento aparecerá.',
      },
      {
        quiz: 'Quais informações aparecem rastreamento?',
        text: 'As informações de rastreamento que normalmente aparecem são: Local, hora de Processamento,Data de Despacho,Data de chega no Destino, Informação da disponibilidade do correio para Levantamento.',
      },
      {
        quiz: 'Por que meu número/ID de rastreamento não está funcionando?',
        text: 'Certifique-se de que inseriu o número de rastreamento correto no formato adequado: verifique o comprimento mínimo de 5 caracteres e se há caracteres especiais em sua entrada. Tabulações, vírgulas, espaços e ponto e vírgula são entendidos como separadores entre vários IDs de rastreamento. Se o seu ID de rastreamento não estiver funcionando, entre em contato com o remetente ou Balcão da Portador Diário.',
      },
      {
        quiz: 'Se eu não tiver meu número de rastreamento, ainda é possível rastrear o meu Correio/Carga?',
        text: 'Caso não tenha o número de rastreamento, aconselhamos entrar em contacto com o remetente ou acessar à sua conta no portal de cliente, através do seu contacto ou email,',
      },
    ];

    // error dialog section
    const errorDialog = ref(false);
    const errorMsg = ref<string>('');
    const closeErrorDialog = () => {
      errorDialog.value = false;
      errorMsg.value = '';
    };

    const code = ref();
    const loading = ref(false);
    const hasData = ref(false);
    const myPack = ref<Track>();
    const trackPackage = async () => {
      if (code.value == undefined) {
        errorMsg.value = 'Código de tracking é obrigatorio';
        errorDialog.value = true;
        return;
      }
      const mycode = code.value;
      try {
        const res = await trackPack(mycode);
        if (res) {
          myPack.value = res;
          hasData.value = true;
        }
        // console.log('myData:', myData.value); // Log the assigned data
      } catch (error: any) {
        myPack.value = new Track();
        hasData.value = false;
        // console.error('Error fetching data:', error);
      } finally {
        loading.value = false;
      }
    };

    const newTracking = () => {
      myPack.value = new Track();
      code.value = '';
      hasData.value = false;
    };

    return {
      errorDialog,
      errorMsg,
      closeErrorDialog,
      logo,
      icons,
      code,
      myPack,
      trackPackage,
      newTracking,
      hasData,
      faqs,
    };
  },
});
</script>
