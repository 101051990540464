// store.ts
import { InjectionKey } from 'vue';
import { createStore, Store } from 'vuex';
import authModule from './auth';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {
  // You can remove the count state
}
// define injection key
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const key: InjectionKey<Store<any>> = Symbol();

export const store = createStore<State>({
  state: {},
  modules: {
    auth: authModule,
    // Add other modules here if needed
  },
});
